import React, { useState } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  DatePicker,
  Row,
  Col,
  Select,
  message,
  Space,
  Flex,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Switch from "@mui/material/Switch";
import styles from "./updatepatient.styles.css"
import { Box, Paper } from "@mui/material";
import { red } from "@mui/material/colors";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Option } = Select;

function UpdatePatient({ patient }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
    setFormFields();
  };

  const setFormFields = () => {
    form.setFieldsValue({
      firstName: patient["First Name"],
      lastName: patient["Last Name"],
      emails: patient.Email,
      addresses: patient.addresses,
      phoneNumbers: patient.phoneNumbers,
      status: patient.Status,
      DOB: patient.DOB ? dayjs(patient.DOB) : undefined,
      consentForm: patient.ConsentForm,
      sex: patient.sex,
    });
  };

  const handleFormSubmit = async (values) => {
    setConfirmLoading(true);
    try {
      const payload = {
        "First Name": values.firstName,
        "Last Name": values.lastName,
        "Full Name": `${values.firstName} ${values.lastName}`,
        Email: values.emails,
        addresses: values.addresses,
        phoneNumbers: values.phoneNumbers,
        Status: values.status,
        ConsentForm: values.consentForm,
        DOB: values.DOB ? values.DOB.toISOString() : null,
        sex: values.sex,
      };

      const response = await fetch(
        `/patient-profiles/${patient._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 200) {
        message.success("Patient updated successfully");

        setOpen(false);
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      message.error("Failed to update patient");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn-color-BorderOnly"
        type="primary"
        onClick={showModal}
      >
        Edit Patient
      </Button>
      <Modal
        title="Update Patient"
        open={open}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          name="patientForm"
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <Box component={Paper} sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label={<p className={styles["label"]}>First Name</p>}
                  rules={[
                    { required: true, message: "Please enter First Name" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label={<p className={styles["label"]}>Last Name</p>}
                  rules={[
                    { required: true, message: "Please enter Last Name" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Box>
          <Box component={Paper} sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}>
            <Form.List name="emails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      {index > 0 && (
                        <div className={styles["horizontal-line"]}></div>
                      )}
                      <Flex
                        key={key}
                        // style={{ display: "flex", marginBottom: 8 }}
                        justify="space-between"
                        align="center"
                        style={{ marginBottom: 14 }}
                      >
                        <Flex align="center" flex={1} gap={"middle"}>
                          <Form.Item
                            {...restField}
                            name={[name, "email"]}
                            rules={[
                              { required: true, message: "Missing email" },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "tag"]}
                            rules={[{ required: true, message: "Missing tag" }]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Tag" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "isDefault"]}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Switch
                              checkedChildren="Default"
                              unCheckedChildren="Not Default"
                            />
                          </Form.Item>
                        </Flex>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ color: red[500], fontSize: 18 }}
                        />
                      </Flex>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Email
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Box>
          <Box component={Paper} sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}>
            <Form.List name="phoneNumbers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      {index > 0 && (
                        <div className={styles["horizontal-line"]}></div>
                      )}
                      <Flex
                        key={key}
                        // style={{ display: "flex", marginBottom: 8 }}
                        justify="space-between"
                        align="center"
                        style={{ marginBottom: 14 }}
                      >
                        <Flex align="center" flex={1} gap={"middle"}>
                          <Form.Item
                            {...restField}
                            name={[name, "number"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing phone number",
                              },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Phone Number" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "tag"]}
                            rules={[{ required: true, message: "Missing tag" }]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Tag" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "isDefault"]}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Switch
                              checkedChildren="Default"
                              unCheckedChildren="Not Default"
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ color: red[500], fontSize: 18 }}
                          />
                        </Flex>
                      </Flex>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Phone Number
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Box>
          <Box component={Paper} sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}>
            <Form.List name="addresses">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      {index > 0 && (
                        <div className={styles["horizontal-line"]}></div>
                      )}
                      <Flex
                        key={key}
                        vertical
                        gap={"middle"}
                        // justify="space-between"
                        justify="center"
                        style={{ marginBottom: 8 }}
                      >
                        <Flex align="center" flex={1} gap={"middle"}>
                          <Form.Item
                            {...restField}
                            name={[name, "address1"]}
                            rules={[
                              { required: true, message: "Missing address" },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Address Line 1" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "address2"]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Address Line 2" />
                          </Form.Item>
                        </Flex>
                        <Flex align="center" flex={1} gap={"middle"}>
                          <Form.Item
                            {...restField}
                            name={[name, "city"]}
                            rules={[
                              { required: true, message: "Missing city" },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="City" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "province"]}
                            rules={[
                              { required: true, message: "Missing province" },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Province" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "provinceCode"]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Province Code" />
                          </Form.Item>
                        </Flex>
                        <Flex align="center" flex={1} gap={"middle"}>
                          <Form.Item
                            {...restField}
                            name={[name, "country"]}
                            rules={[
                              { required: true, message: "Missing country" },
                            ]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Country" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "countryCode"]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Country Code" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "zip"]}
                            rules={[{ required: true, message: "Missing zip" }]}
                            style={{ width: "50%", marginBottom: 0 }}
                          >
                            <Input placeholder="Zip" />
                          </Form.Item>
                        </Flex>
                        <Flex
                          align="center"
                          flex={1}
                          gap={"middle"}
                          justify="space-between"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "isDefault"]}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Switch
                              checkedChildren="Default"
                              unCheckedChildren="Not Default"
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ color: red[500], fontSize: 18 }}
                          />
                        </Flex>
                      </Flex>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Address
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Box>
          <Box component={Paper} sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label={<p className={styles["label"]}>Status</p>}
                  rules={[{ required: true, message: "Please select Status" }]}
                >
                  <Select>
                    <Option value="Active">Active</Option>
                    <Option value="Inactive">Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="DOB"
                  label={<p className={styles["label"]}>Date of Birth</p>}
                  rules={[
                    { required: true, message: "Please select Date of Birth" },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="MM-DD-YYYY"
                    defaultValuevalue={dayjs(form.getFieldValue("DOB"))}
                    onChange={(date) => form.setFieldsValue({ DOB: date })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>

              <Col span={8}>
                <Form.Item
                  name="consentForm"
                  label={<p className={styles["label"]}>Consent Form</p>}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="sex"
                  label={<p className={styles["label"]}>Sex</p>}
                >
                  <Select>
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>




          </Box>
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#1976d2",
                borderColor: "#1976d2",
                color: "white",
                fontWeight: "bold",
                width: "100%",
              }}
              className={styles["submit-button"]}
              type="primary"
              htmlType="submit"
              loading={confirmLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdatePatient;
