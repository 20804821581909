// Components/TreatmentTable.js
import React, { useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const TreatmentTable = ({ rowData, columnDefs, onCellValueChanged }) => {
  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      headerComponent: undefined,
      headerComponentParams: undefined,
    }),
    []
  );

  return (
    <div className="ag-theme-quartz" style={{ height: 600, width: "100%" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        domLayout="normal"
        pagination={true}
        paginationPageSize={20}
        onCellValueChanged={onCellValueChanged}
      />
    </div>
  );
};

export default TreatmentTable;