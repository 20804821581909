// Components/ColumnFilters.js
import React from "react";
import { Radio } from "antd";

const ColumnFilters = ({ filter, onFilterChange }) => (
  <Radio.Group onChange={onFilterChange} value={filter}>
    <Radio.Button value="all">All</Radio.Button>
    <Radio.Button value="procedures">Procedures</Radio.Button>
    <Radio.Button value="dispensed">Dispensed</Radio.Button>
    <Radio.Button value="labs">Labs</Radio.Button>
   
    
  </Radio.Group>
);

export default ColumnFilters;