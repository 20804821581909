// Components/CellRenderers.js
import React, { useState } from "react";
import { Select, Checkbox, Input, InputNumber } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { Option } = Select;

export const DateCellRenderer = (props) => {
  const handleChange = (date) => {
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      props.setValue(formattedDate);
    } else {
      props.setValue(null);
    }
  };

  return (
    <DatePicker
      value={props.value ? dayjs(props.value) : null}
      onChange={handleChange}
      style={{ width: "100%" }}
    />
  );
};

export const DropdownCellRenderer = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (newValue) => {
    setValue(newValue);
    props.setValue(newValue);
  };

  return (
    <Select style={{ width: "100%" }} value={value} onChange={handleChange}>
      {props.values.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export const CheckboxCellRenderer = (props) => {
  const handleChange = (e) => {
    const newValue = e.target.checked;
    props.setValue(newValue);
  };

  return <Checkbox checked={props.value} onChange={handleChange} />;
};

export const NoteCellRenderer = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    props.setValue(newValue);
  };

  return <Input value={value} onChange={handleChange} />;
};

export const QuantityCellRenderer = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (newValue) => {
    setValue(newValue);
    props.setValue(newValue);
  };

  return (
    <InputNumber
      min={0}
      value={value}
      onChange={handleChange}
      style={{ width: "100%" }}
    />
  );
};

export const CheckboxHeader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <Checkbox disabled checked={true} />
  </div>
);
