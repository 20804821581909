import React, { useState } from "react";
import styles from "./styles.module.css";
import { Row, Col, message } from "antd";
import logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { GoogleLogin } from "@react-oauth/google";
import Loader from "../../Components/Loader";
const Login = () => {
  // State to hold the userName and password values
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleLogin = async () => {
    setError("");
    if (!userName.trim() || !password.trim()) {
      return setError("All fields are required.");
    }

    setLoader(true);
    try {
      const response = await axios.post(`/auth/signin`, {
        username: userName,
        password,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        localStorage.clear("user");
        localStorage.clear("sessionToken");
        localStorage.setItem("sessionToken", response.data.token);

        localStorage.setItem("user", response.data.type);
        setLoader(false);
        // Cookies.set('admin_cookie', response.data.userId, { expires: 1, path: "/", });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      setLoader(false);

      toast.error(error.response.data.error);

      console.error("Signup Error:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const [messageApi, contextHolder] = message.useMessage();
  const handleGoogleLogin = (credentialResponse) => {
    const token = credentialResponse.credential;
    setLoader(true);
    fetch("/auth/verify-google-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => {
        if (response.status === 200) {
          // Successful authentication
          setLoader(false);
          toast.success("Logged in successfully")
          return response.json();

        } else if (response.status === 403) {
          // Successful authentication
          setLoader(false);
          toast.error("User not Allowed by Admin!");
          throw new Error("Failed authentication");
        } else {
          toast.error("Authentication Failed")
          throw new Error("Failed authentication");
        }
      })
      .then((data) => {
        setLoader(false);
        // Log the message from the server
        localStorage.setItem("sessionToken", data.token);
        localStorage.setItem("user", data.type);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
      });
  };

  return (
    <>
      {/* Loader */}
      {loader && <Loader />}

      <div>
        {/* Login form main container */}
        <div className={`${styles.loginCotainerLoginView}`}>
          {/* Login form fields */}
          <div className={styles.login_box}>
            {/* Logo Image */}
            <div className={`${styles.logo}`}>
              <img className={styles.img1} src={logo} alt="Logo" />
            </div>

            <div className={styles.login_header}>Sign In to Your Account</div>
            {/* <div className={`${styles.login_label} font-semibold`}>Username</div> */}
            <input
              type="userName"
              className={`${styles.login_input} border border-gray-200 px-4`}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Username"
              onKeyDown={handleKeyPress}
            />
            {/* <div className={styles.login_label}>Password</div> */}
            <input
              type="password"
              className={`${styles.login_input} border border-gray-200 mt-2`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              onKeyDown={handleKeyPress}
            />
            {/* login button */}
            <div
              className={`${styles.login_btn} font-semibold`}
              onClick={handleLogin}
            >
              Log In
            </div>
            {/* error */}
            {error && <div className={styles.error_message}>{error}</div>}
            {/* Google login aid */}
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleGoogleLogin(credentialResponse);
              }}
              onError={() => {}}
              theme="filled_blue"
              shape="circle"
              useOneTap
            />
            {/* Button */}
            <div className={styles.login_signup}>
              New Here?{" "}
              <span className={styles.login_signup_highlight}>
                Get Access from Gio Franco
              </span>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default Login;
