// Components/PatientSearch.js
import React from "react";
import { Select } from "antd";

const { Option } = Select;

const PatientSearch = ({ patients, onSearch, onSelectPatient }) => (
  <Select
    showSearch
    style={{ width: 300 }}
    placeholder="Search for a patient"
    defaultActiveFirstOption={false}
    showArrow={false}
    filterOption={false}
    onSearch={onSearch}
    onChange={onSelectPatient}
    notFoundContent={null}
  >
    {patients.map((patient) => (
      <Option key={patient.id} value={patient.id}>
        {patient.name}
      </Option>
    ))}
  </Select>
);

export default PatientSearch;